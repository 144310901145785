<template>
	<div class="main" id="columnarAAA">
		
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		data(){
			return{
				renderData:null
			}
		},
		props:{
			columnar:{
				type:Object,
				require:true
			}
		},
		watch:{
			columnar:{
				handler(newV) {
					this.getEcharts()
				}
			}
		},
		methods:{
			getEcharts(){
				var option = {
					grid:{
						top:"20%",
						left:"10%",
						right:"5%",
						bottom:"10%"
					},
					title: {
						text: '',
					},
					// 提示框
					tooltip: {},
					// 图例
					legend: {
					data: ['申请次数','申请机构数']
					},
					// 表示x轴坐标
					xAxis: {
					data:JSON.parse(JSON.stringify(this.columnar.xAxis))
					},
					// 表示y轴坐标
					yAxis: {
						show:true,
						minInterval: 1, //分割刻度
					},
					// 
					series: [
						{
							name: '申请次数',
							type: 'bar',
							data: JSON.parse(JSON.stringify(this.columnar.xAxisData1)) ,
							barWidth:"20%",
							itemStyle: {
								normal: {
									label: {
										show: true,
										position: 'top',
										textStyle: {
											color: '#595959'
										}
									},
									barBorderRadius:[10, 10, 0, 0] 
								}
							},
							color:this.columnar.xAxisData1Color
						},
						 {
							name: '申请机构数',
							type: 'bar',
							data:  JSON.parse(JSON.stringify(this.columnar.xAxisData2)),
							type: 'bar',
							barWidth:"20%",
							// showBackground: true,
							itemStyle: {
								normal: {
									label: {
										show: true,
										position: 'top',
										textStyle: {
											color: '#595959'
										}
									},
									barBorderRadius:[10, 10, 0, 0]
								}
							},
							color: this.columnar.xAxisData2Color
						}
					]
				};
				const chartElement = document.querySelector('#columnarAAA')
				const chart = echarts.init(chartElement,null,{
					renderer:"svg"
				});
				chart.setOption(option);
			},
		},
		mounted(){
			this.getEcharts()
		},
		
	}
</script>

<style scoped>
		.main{
			height: 500px;
		}
</style>