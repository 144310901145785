<template>
	<div class="main" id="echarts">
		
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		data(){
			return{
			}
		},
		props:{
			echartsValue:{
				type: Object,
				required: true,
			}
		},
		watch:{
			echartsValue(newV){
				this.getEcharts()
			}
		},
		methods:{
			getEcharts(){
				var option = {
				  series: [
					{
					  type: 'gauge',
					  center: ['50%', '60%'],
					  startAngle: 200,
					  endAngle: -20,
					  min: 0,
					  max: 100,
					  splitNumber: 10,
					  itemStyle: {
						  // 箭头和指针
						color: this.echartsValue.arrowsColor,
					  },
					  progress: {
						show: true,
						width: 15
					  },
					  pointer: {
						show: true
					  },
					  axisLine: {
						lineStyle: {
						  width: 15
						}
					  },
					  axisTick: {
						distance: -25,
						splitNumber:8 ,
						lineStyle: {
						  width: -2,
						  // 刻度盘
						  color: this.echartsValue.scaleColor
						}
					  },
					  splitLine: {
						distance: -20,
						length: 5,
						lineStyle: {
						  width: 2,
						  // // 分刻度
						  color: this.echartsValue.scaleDivisionColor
						}
					  },
					  axisLabel: {
						distance: -20,
						// 数字刻度
						color:  this.echartsValue.numberColor,
						fontSize: 14
					  },
					  anchor: {
						show: false
					  },
					  title: {
						show: false
					  },
					  detail: {
						valueAnimation: true,
						width: '60%',
						lineHeight: 10,
						borderRadius: 8,
						offsetCenter: [0, '50%'],
						fontSize: 12,
						fontWeight: 'bolder',
						formatter: '报告指数:{value}分',
						color: 'inherit'
					  },
					  data: [
						{
							// 最后的分数
						  value: this.echartsValue.valueNumer
						}
					  ]
					}
				  ]
				};
						
				const chartElement = document.querySelector('#echarts')
				const chart = echarts.init(chartElement,null,{
					renderer:"svg"
				});
				chart.setOption(option);
			}
		},
		mounted(){
			this.getEcharts()
		},
	}
</script>

<style scoped>
		.main{
			/* width: 100%; */
			height: 400px;
		}
</style>